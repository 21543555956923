import React, { useState } from 'react';
import { getLinkByPageName } from '../../lib/links';
import { capitalize } from '../../lib/utils';
import VideoContainer from '../VideoContainer';
import Layout from './styles';
import {
  EACampuses,
  LandingEACampus
} from '../../template/LandingElectronicArts/interfaces';

interface CampusAssets {
  [key: string]: {
    img: string;
    video?: string;
    imgAlternative?: string;
  };
}

const campusesAssets: CampusAssets = {
  ams: {
    img: '/landings/electronic-arts/campuses/amsterdam.jpg',
    video: 'ahoc9MZE38Y'
  },
  ber: {
    img: '/landings/electronic-arts/campuses/berlin.jpg',
    video: 'VW52Pj_b9so'
  },
  bcn: {
    img: '/landings/electronic-arts/campuses/barcelona.jpg',
    video: 'lkLqzWkTmPA'
  },
  lis: {
    img: '/landings/electronic-arts/campuses/lisbon.jpg',
    imgAlternative: '/landings/electronic-arts/campuses/lisbon-alternative.jpg'
  },
  mad: {
    img: '/landings/electronic-arts/campuses/madrid.jpg',
    video: 'XrBoQjm0WKE'
  },
  mex: {
    img: '/landings/electronic-arts/campuses/mexico-city.jpg',
    video: 'AdWmQNmzNpk'
  },
  mia: {
    img: '/landings/electronic-arts/campuses/miami.jpg',
    video: 'OXZWp1eVedo'
  },
  par: {
    img: '/landings/electronic-arts/campuses/paris.jpg',
    video: 'I1Iv3aUP6FU'
  },
  sao: {
    img: '/landings/electronic-arts/campuses/sao-paulo.jpg',
    video: '8MpW2htF46g'
  }
};

const courseDescription: { [key: string]: string } = {
  webft: 'Web Development Full-Time',
  webpt: 'Web Development Part-Time',
  uxuift: 'UX/UI Design Full-Time',
  uxuipt: 'UX/UI Design Part-Time',
  dataft: 'Data Analytics Full-Time',
  datapt: 'Data Analytics Part-Time'
};

interface Cohort {
  code: string;
  format: string;
  date: string;
}
const cohorts: {
  [key: string]: Cohort[];
} = {
  ams: [
    { code: 'web', format: 'pt', date: '07/01/2020 - 04/07/2020' },
    { code: 'data', format: 'pt', date: '07/01/2020 - 04/07/2020' },
    { code: 'web', format: 'ft', date: '17/02/2020 - 17/04/2020' },
    { code: 'uxui', format: 'ft', date: '17/02/2020 - 17/04/2020' },
    { code: 'data', format: 'ft', date: '17/02/2020 - 17/04/2020' },
    { code: 'web', format: 'ft', date: '04/05/2020 - 10/07/2020' },
    { code: 'uxui', format: 'ft', date: '04/05/2020 - 10/07/2020' },
    { code: 'data', format: 'ft', date: '04/05/2020 - 10/07/2020' },
    { code: 'web', format: 'ft', date: '15/06/2020 - 14/08/2020' },
    { code: 'data', format: 'ft', date: '15/06/2020 - 14/08/2020' },
    { code: 'uxui', format: 'pt', date: '30/06/2020 - 19/12/2020' },
    { code: 'data', format: 'pt', date: '30/06/2020 - 19/12/2020' },
    { code: 'web', format: 'ft', date: '27/07/2020 - 25/09/2020' },
    { code: 'uxui', format: 'ft', date: '27/07/2020 - 25/09/2020' },
    { code: 'web', format: 'ft', date: '12/10/2020 - 11/12/2020' },
    { code: 'uxui', format: 'ft', date: '12/10/2020 - 11/12/2020' },
    { code: 'uxui', format: 'pt', date: '14/10/2020 - 11/07/2020' },
    { code: 'data', format: 'ft', date: '12/10/2020 - 11/12/2020' }
  ],
  bcn: [
    { code: 'web', format: 'ft', date: '17/02/2020 - 17/04/2020' },
    { code: 'uxui', format: 'ft', date: '17/02/2020 - 17/04/2020' },
    { code: 'web', format: 'pt', date: '19/03/2020 - 19/09/2020' },
    { code: 'uxui', format: 'pt', date: '19/03/2020 - 19/09/2020' },
    { code: 'web', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'uxui', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'data', format: 'ft', date: '30/03/2020 - 29/05/2020' },
    { code: 'data', format: 'pt', date: '16/06/2020 - 12/12/2020' }
  ],
  ber: [
    { code: 'web', format: 'pt', date: '28/01/2020 - 07/08/2020' },
    { code: 'uxui', format: 'pt', date: '28/01/2020 - 07/08/2020' },
    { code: 'uxui', format: 'ft', date: '16/03/2020 - 22/05/2020' },
    { code: 'web', format: 'ft', date: '16/03/2020 - 22/05/2020' },
    { code: 'data', format: 'ft', date: '16/03/2020 - 22/05/2020' },
    { code: 'web', format: 'ft', date: '01/06/2020 - 07/08/2020' },
    { code: 'data', format: 'ft', date: '01/06/2020 - 07/08/2020' },
    { code: 'web', format: 'ft', date: '10/08/2020 - 16/10/2020' },
    { code: 'uxui', format: 'ft', date: '10/08/2020 - 16/10/2020' },
    { code: 'web', format: 'pt', date: '25/08/2020 - 20/03/2021' },
    { code: 'uxui', format: 'pt', date: '25/08/2020 - 20/03/2021' },
    { code: 'data', format: 'ft', date: '10/08/2020 - 16/10/2020' },
    { code: 'data', format: 'pt', date: '25/08/2020 - 20/03/2021' }
  ],
  lis: [
    { code: 'web', format: 'ft', date: '13/04/2020 - 13/06/2020' },
    { code: 'uxui', format: 'ft', date: '13/04/2020  - 13/06/2020' },
    { code: 'data', format: 'ft', date: '13/04/2020 - 13/06/2020' },
    { code: 'web', format: 'ft', date: '29/06/2020 - 28/08/2020' },
    { code: 'data', format: 'ft', date: '29/06/2020 - 28/08/2020' }
  ],
  mad: [
    { code: 'web', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'uxui', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'data', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'web', format: 'pt', date: '27/04/2020 - 29/10/2020' },
    { code: 'uxui', format: 'pt', date: '27/04/2020 - 29/10/2020' },
    { code: 'data', format: 'pt', date: '27/04/2020 - 29/10/2020' }
  ],
  mex: [
    { code: 'web', format: 'ft', date: '06/01/2020 - 06/03/2020' },
    { code: 'uxui', format: 'ft', date: '06/01/2020 - 06/03/2020' },
    { code: 'data', format: 'ft', date: '06/01/2020 - 06/03/2020' },
    { code: 'web', format: 'pt', date: '07/01/2020 - 03/07/2020' },
    { code: 'web', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'uxui', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'uxui', format: 'pt', date: '16/03/2020 - 12/09/2020' },
    { code: 'data', format: 'ft', date: '23/03/2020 - 22/05/2020' },
    { code: 'data', format: 'pt', date: '16/03/2020 - 12/09/2020' },
    { code: 'web', format: 'ft', date: '08/06/2020 - 07/08/2020' },
    { code: 'uxui', format: 'ft', date: '08/06/2020 - 07/08/2020' },
    { code: 'data', format: 'ft', date: '08/06/2020 - 07/08/2020' }
  ],
  mia: [
    { code: 'web', format: 'ft', date: '01/20/2020 - 03/27/2020' },
    { code: 'uxui', format: 'ft', date: '01/20/2020 - 03/27/2020' },
    { code: 'data', format: 'ft', date: '01/20/2020 - 03/27/2020' },
    { code: 'web', format: 'ft', date: '03/30/2020 - 06/05/2020' },
    { code: 'uxui', format: 'ft', date: '03/30/2020 - 06/05/2020' },
    { code: 'data', format: 'ft', date: '03/30/2020 - 06/05/2020' },
    { code: 'web', format: 'pt', date: '06/01/2020 - 12/04/2020' },
    { code: 'uxui', format: 'pt', date: '06/01/2020 - 12/04/2020' },
    { code: 'data', format: 'pt', date: '06/01/2020 - 12/04/2020' },
    { code: 'web', format: 'ft', date: '06/08/2020 - 08/14/2020' },
    { code: 'uxui', format: 'ft', date: '06/08/2020 - 08/14/2020' },
    { code: 'data', format: 'ft', date: '06/08/2020 - 08/14/2020' }
  ],
  par: [
    { code: 'uxui', format: 'pt', date: '03/02/2020 - 14/08/2020' },
    { code: 'data', format: 'pt', date: '03/02/2020 - 14/08/2020' },
    { code: 'web', format: 'ft', date: '23/03/2020 - 29/05/2020' },
    { code: 'uxui', format: 'ft', date: '23/03/2020 - 29/05/2020' },
    { code: 'data', format: 'ft', date: '23/03/2020 - 29/05/2020' },
    { code: 'web', format: 'ft', date: '08/06/2020 - 14/08/2020' },
    { code: 'uxui', format: 'ft', date: '08/06/2020 - 14/08/2020' },
    { code: 'data', format: 'ft', date: '08/06/2020 - 14/08/2020' },
    { code: 'web', format: 'pt', date: '02/06/2020 - 19/12/2020' },
    { code: 'web', format: 'ft', date: '24/08/2020 - 30/10/2020' },
    { code: 'uxui', format: 'ft', date: '24/08/2020 - 30/10/2020' },
    { code: 'data', format: 'ft', date: '24/08/2020 - 30/10/2020' }
  ],
  sao: [
    { code: 'web', format: 'ft', date: '10/02/2020 - 24/04/2020' },
    { code: 'web', format: 'ft', date: '27/04/2020 - 03/07/2020' },
    { code: 'web', format: 'ft', date: '06/07/2020 - 11/09/2020' },
    { code: 'web', format: 'ft', date: '14/09/2020 - 20/11/2020' },
    { code: 'web', format: 'ft', date: '23/11/2020 - 29/01/2021' },
    { code: 'web', format: 'pt', date: '07/01/2020 - 04/07/2020' },
    { code: 'web', format: 'pt', date: '01/04/2020 - 19/09/2020' },
    { code: 'web', format: 'pt', date: '07/07/2020 - 19/12/2020' },
    { code: 'web', format: 'pt', date: '29/09/2020 - 13/03/2021' },
    { code: 'uxui', format: 'ft', date: '10/02/2020 - 24/04/2020' },
    { code: 'uxui', format: 'ft', date: '27/04/2020 - 03/07/2020' },
    { code: 'uxui', format: 'ft', date: '06/07/2020 - 11/09/2020' },
    { code: 'uxui', format: 'ft', date: '14/09/2020 - 20/11/2020' },
    { code: 'uxui', format: 'ft', date: '23/11/2020 - 29/01/2021' },
    { code: 'uxui', format: 'pt', date: '07/01/2020 - 04/07/2020' },
    { code: 'uxui', format: 'pt', date: '07/07/2020 - 19/12/2020' },
    { code: 'data', format: 'ft', date: '10/02/2020 - 24/04/2020' },
    { code: 'data', format: 'ft', date: '27/04/2020 - 03/07/2020' },
    { code: 'data', format: 'ft', date: '06/07/2020 - 11/09/2020' },
    { code: 'data', format: 'ft', date: '14/09/2020 - 20/11/2020' },
    { code: 'data', format: 'ft', date: '23/11/2020 - 29/01/2021' },
    { code: 'data', format: 'pt', date: '07/01/2020 - 04/07/2020' },
    { code: 'data', format: 'pt', date: '07/07/2020 - 19/12/2020' }
  ]
};

interface Props {
  campuses: EACampuses;
  isFinished?: boolean;
  lang: string;
}
function CampusSelector(props: Props) {
  const {
    noCampusTitle,
    campusTitle,
    selectAnother,
    discover,
    items,
    courses
  } = props.campuses;
  const { isFinished = false, lang } = props;
  const [currentCampus, setCurrentCampus] = useState('');
  function renderCampusList() {
    return (
      <section className="campus-list">
        <h3>{noCampusTitle}</h3>
        {items.map((campus: LandingEACampus) => {
          return isFinished ? (
            <a
              key={campus.code}
              className="campus-list-item"
              href={`${getLinkByPageName(
                `campus${capitalize(campus.code)}`,
                lang
              )}`}
            >
              <img src={campusesAssets[campus.code].img} alt={campus.name} />
              <div>
                <b>{campus.name}</b>
                Data Analytics, UX/UI Design, Web Dev
              </div>
            </a>
          ) : (
            <article
              key={campus.code}
              className="campus-list-item"
              onClick={() => setCurrentCampus(campus.code)}
            >
              <img src={campusesAssets[campus.code].img} alt={campus.name} />
              <div>
                <b>{campus.name}</b>
                Data Analytics, UX/UI Design, Web Dev
              </div>
            </article>
          );
        })}
      </section>
    );
  }

  function renderSelectedCampus() {
    const campusInfo = items.find(
      (campus: LandingEACampus) => campus.code === currentCampus
    );
    if (!campusInfo) {
      return <div>Campus not found</div>;
    }

    return (
      <section className="current-campus">
        <header>
          <h3>
            {campusTitle} <em>{campusInfo.city}</em>
          </h3>

          <span className="button" onClick={() => setCurrentCampus('')}>
            {selectAnother}
          </span>
        </header>

        <section className="courses">
          <h3>{courses.title}</h3>
          <p
            className="scholarship-amount"
            dangerouslySetInnerHTML={{
              __html: campusInfo.amounts
            }}
          />

          <article className="table">
            <header className="header">
              <div className="col-1">Course</div>
              <div className="col-2">Dates</div>
            </header>

            {cohorts[campusInfo.code] ? (
              cohorts[campusInfo.code].map((cohort: Cohort, index: number) => (
                <div key={`campus-${campusInfo.code}-${index}`} className="row">
                  <div className="col-1">
                    {courseDescription[`${cohort.code}${cohort.format}`]}
                  </div>
                  <div className="col-2">
                    {cohort.date}
                    <span>
                      {cohort.format === 'ft' ? campusInfo.ft : campusInfo.pt}
                    </span>
                  </div>
                  <div className="col-3"></div>
                </div>
              ))
            ) : (
              <div className="no-cohorts">{courses.noCohorts}</div>
            )}
          </article>
        </section>

        <section className="our-campus">
          <h3>{discover}</h3>
          <article>
            <p dangerouslySetInnerHTML={{ __html: campusInfo.address }} />
          </article>

          <article>
            <VideoContainer
              isVideo={(campusesAssets[currentCampus].video || '').length > 0}
              href={
                (campusesAssets[currentCampus].video &&
                  `https://www.youtube.com/embed/${campusesAssets[currentCampus].video}`) ||
                campusesAssets[currentCampus].imgAlternative ||
                ''
              }
            />
          </article>
        </section>
      </section>
    );
  }

  if (isFinished) {
    return <Layout>{renderCampusList()}</Layout>;
  }

  return (
    <Layout>
      {currentCampus ? renderSelectedCampus() : renderCampusList()}
    </Layout>
  );
}

export default CampusSelector;
