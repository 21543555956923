import React from 'react';
import Helmet from 'react-helmet';
import Header from '../../components-landing/Header';
import Navbar from '../../components-landing/NavbarElectronicArts';
import Video from '../../components-landing/VideoContainer';
import ValueProposition from '../../components-landing/ValueProps';
import OurCourses from '../../components-landing/OurCourses';
import ApplicationProcess from '../../components-landing/ApplicationProcess';
import PardotForm from '../../components-landing/PardotForm';
import Faq from '../../components-landing/Faq';
import Campus from '../../components-landing/Campus';
import CampusSelector from '../../components-landing/CampusSelector';
import MoreInfo from '../../components-landing/MoreInfo';
import Footer from '../../components-landing/Footer';
import Layout from './styles';
import { PropsLanding } from './interfaces';
import withI18next from '../../i18n/TemplateI18n';
import { graphql } from 'gatsby';

const baseUrl = process.env.IRONHACK_URL;
const LandingPage: React.SFC<any> = (props: PropsLanding) => {
  const {
    url,
    lang,
    breadcrumb,
    customColor,
    seo,
    header,
    navbar,
    video,
    valueProposition,
    courses,
    applicationProcess,
    faq,
    campus,
    moreInfo,
    termsAndConditions,
    pardotForm,
    campuses
  } = props.pageContext;

  return (
    <Layout customColor={customColor}>
      <Helmet title={seo.title} defer={false}>
        <html lang={lang} />
        <meta name="description" content={seo.description} />
        <link
          rel="alternate"
          hrefLang="X-Default"
          href={`${baseUrl}/en/social-impact/ea-thesims-scholarship`}
        />
        <link
          rel="alternate"
          hrefLang="es"
          href={`${baseUrl}/es/impacto-social/beca-ea-thesims`}
        />
        <link
          rel="alternate"
          hrefLang="fr-FR"
          href={`${baseUrl}/fr/impact-social/bourse-ea-thesims`}
        />
        <link
          rel="alternate"
          hrefLang="de-DE"
          href={`${baseUrl}/de/social-impact/ea-thesims-stipendium`}
        />
        <link
          rel="alternate"
          hrefLang="nl-NL"
          href={`${baseUrl}/nl/sociale-impact/ea-thesims-studiebeurs`}
        />
        <link
          rel="alternate"
          hrefLang="pt-BR"
          href={`${baseUrl}/br/impacto-social/ea-thesims-bolsas`}
        />
        <link
          rel="icon"
          href={`${baseUrl}/assets/favicon/favicon.ico`}
          type="image/x-icon"
        />
      </Helmet>
      <Header {...header} />
      <Navbar
        navbar={navbar}
        pardotForm={pardotForm || { sectionId: '', title: '', formUrl: '' }}
      />
      <Video {...video} />
      <ValueProposition {...valueProposition} />
      <OurCourses {...courses} />
      {applicationProcess && <ApplicationProcess {...applicationProcess} />}
      <CampusSelector
        campuses={campuses}
        pardotForm={pardotForm}
        isFinished
        lang={lang}
      />
      <Faq {...faq} />
      {campus && <Campus {...campus} />}
      {moreInfo && <MoreInfo {...moreInfo} />}
      <Footer
        breadcrumb={{ text: breadcrumb, url }}
        termsAndConditions={termsAndConditions}
      />
    </Layout>
  );
};

export const query = graphql`
  query($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: { regex: "/(breadcrumbs)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(LandingPage);
