import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { windowDefined } from '../../lib/utils';
import Layout from './styles';
import { LandingNavbar } from '../../template/Landing/interfaces';
import { LandingPardotForm } from '../../template/LandingElectronicArts/interfaces';

interface Props {
  navbar: LandingNavbar;
  pardotForm: LandingPardotForm;
}

function Navbar(props: Props) {
  const { text, linkHref, linkText = 'Apply now' } = props.navbar;
  const { sectionId } = props.pardotForm;
  const [isFixed, setIsFixed] = useState(false);
  const [currentNavbarTop, setCurrentNavbarTop] = useState(0);
  const navbarClass = classNames({ active: isFixed });

  function scrollListener() {
    const currentScrollWithMargin = window.scrollY + 30;
    const navbarTop = document.getElementById('navbar-landing').offsetTop;
    if (!isFixed && currentScrollWithMargin > navbarTop) {
      setIsFixed(true);
      setCurrentNavbarTop(navbarTop);
    } else if (currentScrollWithMargin <= currentNavbarTop) {
      setIsFixed(false);
    }
  }

  function getText(paragraphText: string | string[]) {
    if (typeof paragraphText === 'string') {
      return paragraphText;
    }
    return paragraphText.map((textString: string, index: number) => (
      <p key={`nav-${index}`}>{textString}</p>
    ));
  }

  function handleButtonClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) {
    e.preventDefault();
    const pardotForm = document.getElementById(sectionId);
    if (pardotForm && windowDefined()) {
      window.scroll({
        top: pardotForm.offsetTop - 240,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollListener);
    return () => {
      window.removeEventListener('scroll', scrollListener);
    };
  });

  return (
    <Layout id="navbar-landing" className={navbarClass}>
      <article className="content-wrapper">{getText(text)}</article>
      <a onClick={handleButtonClick} target="_blank" rel="noreferrer noopener">
        {linkText}
      </a>
    </Layout>
  );
}

export default Navbar;
