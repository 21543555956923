import styled from 'styled-components';
import config from '../../components/config';
import Layout from '../../components/atoms/layout';

export default styled(Layout)`
  margin-top: 4.8rem;

  .campus-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .campus-list-item {
    border-radius: ${config.borderRadius};
    box-shadow: 0 0.8rem 1.6rem ${config.palette.boxShadow};
    cursor: pointer;
    margin-bottom: 3.2rem;
    text-decoration: none;
    transition: 0.3s ease-in-out box-shadow;
    width: calc((100% - 3.2rem) / 3);

    &:hover {
      box-shadow: 0 0.8rem 1.6rem ${config.palette.boxShadowHover};
    }

    img {
      border-top-left-radius: ${config.borderRadius};
      border-top-right-radius: ${config.borderRadius};
      width: 100%;
    }

    div {
      color: ${config.palette.darkBlueTransparent('0.8')};
      font-size: 1.4rem;
      line-height: 150%;
      padding: 2.4rem 1.6rem;

      b {
        color: ${config.palette.darkBlue};
        display: block;
        font-size: 2rem;
        line-height: 130%;
        margin-bottom: 0.4rem;
      }
    }
  }

  .current-campus {
    header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      h3 {
        width: auto;

        em {
          color: ${config.palette.lightBlue};
          font-style: normal;
        }
      }

      .button {
        border: 1px solid ${config.palette.darkBlueTransparent('0.1')};
        border-radius: ${config.borderRadius};
        color: ${config.palette.darkBlue};
        cursor: pointer;
        display: block;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 130%;
        padding: 1.6rem 4.8rem;
        transition: 0.3s ease-in-out box-shadow;

        &:hover {
          box-shadow: 0 0.8rem 1.6rem ${config.palette.boxShadowHover};
        }
      }
    }
  }

  .courses {
    margin-bottom: 9.6rem;
    margin-top: 4.8rem;

    .no-cohorts {
      color: ${config.palette.darkBlueTransparent('0.6')};
      font-style: italic;
      padding: 1.6rem 0;
      text-align: center;
    }

    .scholarship-amount {
      color: ${config.palette.darkBlueTransparent('0.8')};
      font-size: 1.6rem;
      line-height: 180%;
      margin-bottom: 2.4rem;

      b {
        color: ${config.palette.darkBlue};
      }
    }

    .table {
      color: ${config.palette.darkBlue};
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 130%;

      .header {
        background: #fcfcfc;
        border-bottom: 1px solid ${config.palette.darkBlueTransparent('0.1')};
        font-size: 1.4rem;
      }

      header,
      .row {
        align-items: flex-start;
        display: flex;
        padding: 1.6rem 0;
      }

      .row {
        border-bottom: 1px solid ${config.palette.darkBlueTransparent('0.1')}};
        padding: 1.4rem 0;
      }

      .col-1 {
        padding-left: 3.2rem;
        width: calc(40% - 3.2rem);
      }

      .col-2 {
        padding-right: 3.2rem;
        width: calc(60% - 3.2rem);

        span {
          display: inline-block;
          font-size: 1.4rem;
          font-weight: 400;
          margin-left: 1.6rem;
          margin-top: 0.4rem;
        }
      }

      .col-3 {
        a {
          background: ${config.palette.lightBlue};
          border-radius: ${config.borderRadius};
          color: ${config.palette.white};
          cursor: pointer;
          display: block;
          font-size: 1.4rem;
          font-weight: 700;
          padding: 0.8rem 1.6rem;
          text-align: center;
          transition: 0.3s ease-in-out background;

          &:hover {
            background: ${config.palette.lightBlueHover};
            text-decoration: none;
          }
        }
      }
    }
  }

  .our-campus {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    & > h3 {
      width: 100%;
    }

    & > article {
      width: 50%;

      & > p {
        color: ${config.palette.darkBlueTransparent('0.6')};
        font-size: 1.8rem;
        margin-bottom: 0.8rem;
      }

      & > img {
        border-radius: ${config.borderRadius};
        width: 100%;
      }

      &:last-child {
        & > section {
          margin-top: 0;
        }
      }
    }
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    .campus-list-item {
      width: 100%;
    }

    .selector {
      & > article {
        width: 100%;
      }
    }

    .current-campus {
      & > header {
        flex-wrap: wrap;

        .campus-name {
          em {
            font-size: 2.4rem;
          }
        }

        .btn-another {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          margin-top: 1.6rem;
          padding: 1.2rem 0;
          text-align: center;
          width: 100%;
        }
      }
    }

    .courses {
      .scholarshipAmount {
        b {
          display: block;
        }
      }

      .table {
        header {
          .col-2 {
            display: none;
          }
        }
        .row {
          flex-wrap: wrap;
        }

        .col-1,
        .col-2,
        .col-3 {
          padding: 0;
          width: 100%;

          span {
            margin: 0;
            margin-left: 0.8rem;
          }
        }
        .col-3 {
          margin-top: 1.6rem;
        }
      }
    }

    .our-campus {
      & > article {
        width: 100%;

        &:last-child {
          margin-top: 2.4rem;
        }
      }
    }
  }
`;
